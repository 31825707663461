import { AxiosPromise, AxiosResponse, AxiosResponseHeaders } from "axios"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { appStore } from "../../../../app/App.Store"
import { IReplaceContract } from "../../../../app/portal/admin/contractsManagement/masterData/ReplaceContract"
import {
  setAdminParticipantBillings,
  setAdminVewaParticipantBillings,
} from "../../../../app/portal/admin/store/billingsSlice"
import {
  BillingSettingsUpdateAdminRequest,
  ContractAdminResponse,
  ContractBuildingsAdminRequest,
  ContractCreateAdminRequest,
  ContractUpdateAdminRequest,
  ContractUpdateContactAdminRequest,
  MeterActivationRequest,
  PagedContractAdminResponse,
  PricingModel,
  ServiceBillingAdminResponse,
  UpdateCommentRequest,
  ZevPricePackage,
} from "../../../../data/generated-sources/openapi"
import { isNewStateBillingProduct } from "../../../../services/utils/BillingUtilis"
import { apiHeaders, fileAttachmentDownload, fileAttachmentName } from "../../../Domain.Calls"
import { managerNamesMapper, mapManagersName } from "../profiles/Profiles.Mapper"
import { getUtilityUnitById } from "../utility-units/UtilityUnits.Repository"

export const getAllContracts = async (
  page?: number,
  limit?: number,
  orderBy?: string,
): Promise<PagedContractAdminResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContracts(
    page ?? 1,
    limit ?? 10000,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return data
}

export const getAllManuallyBilledContracts = async () => {
  const { elements } = await getAllContracts()
  return elements?.filter(({ createServiceBillsManually }) => createServiceBillsManually)
}

export const getPageIndividualParticipantsBillingsByContractId = async (contractId?: string) => {
  const { data: individualBillings } =
    await DOMAIN_DEPENDENCIES.adminContractApi.adminGetPageIndividualParticipantsBillingsByContractId(
      contractId ?? "",
      1,
      undefined,
      undefined,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const mappedElements = await Promise.all(
    individualBillings.elements.map(async (bill) => {
      const utilityUnitData = await getUtilityUnitById(bill.utilityUnitId)

      return {
        ...bill,
        utilityUnit: utilityUnitData,
      }
    }),
  )

  const data = {
    page: individualBillings.page,
    elements: mappedElements,
  }
  return { data }
}

export const getContractManagementById = (contractId: string): Promise<ContractAdminResponse> => {
  return DOMAIN_DEPENDENCIES.adminContractApi
    .adminGetContractById(contractId, apiHeaders(DOMAIN_DEPENDENCIES))
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })
}

export const createContractForArea = async (
  areaId: string,
  contractCreateData: ContractCreateAdminRequest,
): Promise<string> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminAreaApi.adminCreateContractForArea(
    areaId,
    contractCreateData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const updateContractById = (contractId: string, contractUpdateData: ContractUpdateAdminRequest) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminUpdateContractById(
    contractId,
    contractUpdateData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const approveContractById = (contractId: string): Promise<AxiosResponse<void>> => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminReleaseContractById(contractId, apiHeaders(DOMAIN_DEPENDENCIES))
}
export const deleteContractById = (contractId: string): Promise<AxiosResponse<void>> => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminDeleteContractById(contractId, apiHeaders(DOMAIN_DEPENDENCIES))
}

export const activateMetersByContractId = (
  contractId: string,
  meterActivationRequest: Array<MeterActivationRequest>,
): Promise<AxiosResponse<void>> => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminActivateMetersByContractId(
    contractId,
    meterActivationRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const replaceContractById = (
  contractId: string,
  contractData: IReplaceContract,
): Promise<AxiosResponse<string>> => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminReplaceContractById(
    contractId,
    {
      productId: contractData.productId,
      endDate: contractData.endDate,
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getProductsForContract = async () => {
  const { data } = await DOMAIN_DEPENDENCIES.adminProductsAndServicesApi.adminGetProducts(
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const getAllManagersList = async () => {
  const { data } = await DOMAIN_DEPENDENCIES.adminProfileApi.getAllProfiles(
    1,
    1000,
    undefined,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return managerNamesMapper(data, DOMAIN_DEPENDENCIES)
}

export const getManagersByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetManagersByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return mapManagersName(data, DOMAIN_DEPENDENCIES)
}

export const updateManagersByContractId = (
  contractId: string,
  managerIds: Array<string>,
): Promise<AxiosResponse<void>> => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminUpdateManagersByContractId(
    contractId,
    { managerIds: managerIds },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getContractPricePackages = async (contractId: string): Promise<ZevPricePackage[]> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetPricePackagesByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const getBillingSettingsByContractId = (contractId: string) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminGetBillingSettingsByContractId(contractId, apiHeaders(DOMAIN_DEPENDENCIES))

export const updateBillingSettingsByContractId = async (
  contractId: string,
  billingData: BillingSettingsUpdateAdminRequest,
) => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminUpdateBillingSettingsByContractId(
    contractId,
    billingData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getContactByContractId = async (contractId: string): Promise<ContractUpdateContactAdminRequest> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContactByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const updateContactByContractId = async (contractId: string, updateData: ContractUpdateContactAdminRequest) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminUpdateContactByContractId(
    contractId,
    updateData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const updatePricePackagesByContractId = async (contractId: string, updateData: ZevPricePackage[]) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminUpdatePricePackagesByContractId(
    contractId,
    updateData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const getBuildingsByContractId = (contractId: string, page: number, limit: number, orderBy: string) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminGetPageBuildingsByContractId(
    contractId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const updateContractBuildingList = (contractId: string, buildingIdsList: ContractBuildingsAdminRequest) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminUpdateBuildingsByContractId(
    contractId,
    buildingIdsList,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const getProductAndContractByContractId = async (contractId: string) => {
  const { data: contractData } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const { data: productData } = await DOMAIN_DEPENDENCIES.adminProductsAndServicesApi.adminGetProductById(
    contractData?.productId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return {
    contract: contractData,
    product: productData,
  }
}

export const downloadCsvAllParticipantByContractId = async (contractId: string): Promise<void> => {
  const csv = await DOMAIN_DEPENDENCIES.adminContractApi.getAllParticipantByContractIdCsv(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return fileAttachmentDownload(fileAttachmentName(csv.headers as AxiosResponseHeaders), new Blob([csv.data]))
}

export const initServiceBillingByContractId = (contractId: string): AxiosPromise<string> => {
  return DOMAIN_DEPENDENCIES.adminContractApi.adminPostContractInitialServiceBillingById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const initServiceBillingIsAllowed = async (contractId: string): Promise<boolean> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetInitialServiceBillingsByContractId(
    contractId,
    1,
    10000,
    "",
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const isAllowed: boolean = data?.elements?.some((billing: ServiceBillingAdminResponse) => {
    return billing.activeState === "DRAFT" || billing.activeState === "APPROVED"
  })
  return !isAllowed
}

export const getPagedDraftUtilityUnitMetersByContractId = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetPagedDraftUtilityUnitMetersByContractId(
    contractId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const getPagedDraftNonUtilityUnitMetersByContractId = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetPagedDraftNonUtilityUnitMetersByContractId(
    contractId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const getPageAllParticipantsBillingsByContractId = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetPageAllParticipantsBillingsByContractId(
    contractId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return data
}

export const getAllParticipantsBillingsByContractIdWithNewField = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  appStore.dispatch(setAdminParticipantBillings({ participantsBillings: [] }))
  const billings = await getPageAllParticipantsBillingsByContractId(contractId, page, limit, orderBy)
  const billingsWithIsNew = (billings.elements || []).map((bill) => {
    return {
      ...bill,
      isNew: isNewStateBillingProduct(bill?.activeState, bill?.hasCollection),
    }
  })

  const data = {
    ...billings,
    elements: billingsWithIsNew,
  }

  appStore.dispatch(setAdminParticipantBillings({ participantsBillings: billingsWithIsNew }))

  return { data }
}

export const getPagedVewaAllParticipantsBillingsByContractId = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetPagedVewaAllParticipantsBillingsByContractId(
    contractId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const getVewaAllParticipantsBillingsByContractIdWithNewField = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  appStore.dispatch(setAdminVewaParticipantBillings({ vewaParticipantsBillings: [] }))
  const billings = await getPagedVewaAllParticipantsBillingsByContractId(contractId, page, limit, orderBy)

  const billingsWithIsNew = (billings.elements || []).map((bill) => {
    return {
      ...bill,
      isNew: false,
    }
  })

  const data = {
    ...billings,
    elements: billingsWithIsNew,
  }

  appStore.dispatch(setAdminVewaParticipantBillings({ vewaParticipantsBillings: billingsWithIsNew }))

  return { data }
}

export const adminGetProductHistoryByContractId = (contractId: string) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminGetProductHistoryContractId(contractId, apiHeaders(DOMAIN_DEPENDENCIES))

export const adminGetServiceBillingByContractId = (contractId: string) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminGetPricingModelByContractId(contractId, apiHeaders(DOMAIN_DEPENDENCIES))

export const adminUpdateServiceBillingByContractId = (contractId: string, priceModel: PricingModel) =>
  DOMAIN_DEPENDENCIES.adminContractApi.adminUpdatePricingModelByContractId(
    contractId,
    {
      pricingModel: priceModel,
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const updateContractCommentById = (contractId: string, updateCommentRequest: UpdateCommentRequest) =>
  DOMAIN_DEPENDENCIES.adminContractApi.updateContractCommentById(
    contractId,
    updateCommentRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
