import { Box, BoxProps } from "@mui/material"
import React from "react"

interface TabPanelProps extends BoxProps {
  index: unknown
  value: unknown
  children?: React.ReactNode
}

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => {
  if (value !== index) {
    return null
  }
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      data-testid={`tabpanel-${index}`}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default TabPanel
