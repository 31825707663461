import { I18nNamespace } from "../../../../App.i18n"

export const ServiceBillingsI18n: I18nNamespace = {
  name: "service-billings",
  de: {
    "serviceBilling.title": "Dienstleistungs-Abrechnungen",
    manualBilling: "Manuelle Abrechnung",
    billingDate: "Zeitraum",
    invoiceNumber: "Rechnungsnummer",
    billingType: "Abrechungsart",
    anzahl: "anzahl",
    "breadcrumb.billing": "Abrechnungen",
    "breadcrumb.serviceBilling": "Dienstleistungs-Abrechnungen",
    "tab.billings": "Abrechnungen",
    "tab.manuallyBilled": "Manuell Abgerechnet",
  },
  en: {
    "serviceBilling.title": "Service-Billing",
    manualBilling: "Manual billing",
    billingDate: "Period",
    invoiceNumber: "Invoice number",
    billingType: "Billing type",
    anzahl: "Number",
    "breadcrumb.billing": "Billing",
    "breadcrumb.serviceBilling": "Service billing",
    "tab.billings": "Billings",
    "tab.manuallyBilled": "Manually Billed",
  },
}
