import { Box, CircularProgress, Divider, IconButton, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ZevPricePackage } from "../../../../../../data/generated-sources/openapi"
import { initialZevPriceItem } from "../../../../../../domain/portal/admin/prices/Prices.Model"
import {
  customerCreatePricePackageByContractId,
  customerDeleteContractPricePackageById,
  customerGetPricePackagesByContractId,
  customerUpdateContractPricePackageById,
} from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { PlusIcon } from "../../../../../../uikit/Shared.Icon"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"
import { ElectricityPricePackageForm } from "../../../../shared/electricityPrices/ElectricityPricePackageForm"
import { ElectricityPricePackageView } from "../../../../shared/electricityPrices/ElectricityPricePackageView"
import { TariffEnergySupplier } from "../../../../shared/tariffs/tariffEnergySupplier/TariffEnergySupplier"

export interface ExtendedZevPricePackage extends ZevPricePackage {
  newPackage?: boolean
  hasSpikePrice?: boolean
}

export const SettingsElectricityTab = ({ contractId }: { contractId: string }) => {
  const { t } = useTranslation("contracts-management")
  const [error, setError] = useState<AxiosErrorDataType>()
  const [editMode, setEditMode] = useState<number | null>(null)
  const [pricePackages, setPricePackages] = useState<ExtendedZevPricePackage[]>([initialZevPriceItem])

  const {
    isLoading,
    isFetching,
    refetch: refetchPackages,
    remove: removePackages,
  } = useQuery(["getPricePackages"], () => customerGetPricePackagesByContractId(contractId), {
    enabled: !!contractId,
    onError: setError,
    onSuccess: (data) => setPricePackages(data),
  })

  useEffect(() => {
    return () => {
      removePackages()
      setPricePackages([initialZevPriceItem])
    }
  }, [removePackages, contractId])

  const addPricePackage = () => {
    const lastPackage = [...pricePackages]?.pop()
    const isNewPackagePresent = lastPackage?.newPackage
    if (!isNewPackagePresent) {
      let lastId = lastPackage?.id || 0
      const nextIdValue = ++lastId
      setPricePackages([
        ...pricePackages,
        { ...initialZevPriceItem, id: nextIdValue, order: nextIdValue, newPackage: true },
      ])
    }
  }

  const handleSuccessUpdate = () => {
    refetchPackages()
    setEditMode(null)
  }

  const { mutate: createPricePackage, isLoading: isCreatingPackage } = useMutation(
    ["createPricePackage"],
    (updateData: ZevPricePackage) => customerCreatePricePackageByContractId(contractId, updateData),
    {
      onError: setError,
      onSuccess: handleSuccessUpdate,
    },
  )

  const { mutate: updatePricePackage, isLoading: isUpdatingPackage } = useMutation(
    ["updatePricePackage"],
    (updateData: ZevPricePackage) => customerUpdateContractPricePackageById(contractId, updateData.id, updateData),
    {
      onError: setError,
      onSuccess: handleSuccessUpdate,
    },
  )

  const { mutate: deletePricePackage, isLoading: isDeletingPackage } = useMutation(
    ["deletePricePackage"],
    (pricePackageId: number) => customerDeleteContractPricePackageById(contractId, pricePackageId),
    {
      onError: setError,
      onSuccess: handleSuccessUpdate,
    },
  )

  if (isLoading && isFetching) return <CircularProgress />

  return (
    <Box sx={{ p: 4, pl: 6, pt: 3 }}>
      <ErrorAlert
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <TariffEnergySupplier contractId={contractId} />

      <Divider sx={{ my: 5.5 }} />

      <Stack flexDirection={"row"} alignItems={"start"} justifyContent={"flex-start"} flexWrap={"wrap"}>
        {(pricePackages || []).map((pricePackage) => (
          <Box key={pricePackage.id} mr={3}>
            {editMode === pricePackage.id || pricePackage.newPackage ? (
              <ElectricityPricePackageForm
                pricePackage={{ ...pricePackage, hasSpikePrice: !!pricePackage.prices?.spikePrice }}
                onCloseNewPackage={handleSuccessUpdate}
                onUpdatePricePackage={updatePricePackage}
                onCreatePricePackage={createPricePackage}
                onDeletePackageConfirm={deletePricePackage}
                onEditCancel={() => setEditMode(null)}
                isModifying={isUpdatingPackage || isCreatingPackage || isDeletingPackage}
              />
            ) : (
              <ElectricityPricePackageView pricePackage={pricePackage} onSetEditMode={(id) => setEditMode(id)} />
            )}
          </Box>
        ))}
        <IconButton
          onClick={() => addPricePackage()}
          sx={{
            color: "#fff",
            background: "linear-gradient(256.56deg, #9AB20F -22.21%, #4E7818 65.58%)",
          }}
        >
          <PlusIcon fontSize="medium" />
        </IconButton>
      </Stack>
    </Box>
  )
}
