import { Paper } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PrimaryTab, PrimaryTabContainer } from "../../../../../uikit/tabs/PrimaryTab"
import TabPanel from "../../../../../uikit/tabs/TabPanel"
import { ServiceBillingsHeader } from "./ServiceBillingsHeader"
import { ServiceBillingsList } from "./ServiceBillingsList"
import { ServiceBillingsManuallyContracts } from "./ServiceBillingsManuallyContracts"

export const ServiceBillings = () => {
  const { t } = useTranslation("service-billings")
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Paper data-testid="serviceBilingsPage">
      <ServiceBillingsHeader />
      <PrimaryTabContainer
        value={selectedTab}
        variant="scrollable"
        onChange={(_, tab) => setSelectedTab(tab)}
        sx={{ marginBottom: 4 }}
      >
        <PrimaryTab label={t("tab.billings")} data-testid="serviceBilingsTabBillings" />
        <PrimaryTab label={t("tab.manuallyBilled")} data-testid="serviceBilingsTabManuallyBilled" />
      </PrimaryTabContainer>

      <TabPanel value={selectedTab} index={0}>
        <ServiceBillingsList />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <ServiceBillingsManuallyContracts />
      </TabPanel>
    </Paper>
  )
}
