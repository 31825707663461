import { IconButton } from "@mui/material"
import { GridActionsColDef } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { getContractStartEndPeriod } from "../../../../../services/utils/ContractUtils"
import { DefaultCell } from "../../../../../uikit/dataGridTable"
import { OpenIconNew } from "../../../../../uikit/Shared.Icon"

export const ServiceBillingsManuallyContractsColumns = () => {
  const history = useHistory()
  const { t } = useTranslation("shared")

  const serviceBillingsManuallyColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 100,
      field: "datePeriod",
      headerName: t("label.date"),
      valueGetter: (_, row) => row,
      valueFormatter: getContractStartEndPeriod,
      sortComparator: (v1, v2) => (v1?.startDate > v2?.startDate ? 1 : -1),
      renderCell: ({ formattedValue, ...rest }) => <DefaultCell {...rest} value={formattedValue} />,
    },
    {
      flex: 250,
      field: "name",
      headerName: t("label.contract"),
      renderCell: DefaultCell,
    },
    {
      flex: 50,
      field: "id",
      headerName: "ZEV",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <IconButton data-testid="goToContractBtn" onClick={() => history.push(`/management-contracts/${row.id}`)}>
          <OpenIconNew />
        </IconButton>
      ),
    },
  ]
  return serviceBillingsManuallyColumns
}
