import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi/api"
import { getAllManuallyBilledContracts } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { DataGridListPage } from "../../dataGrid/DataGridListPage"
import { ServiceBillingsManuallyContractsColumns } from "./ServiceBillingsManuallyContractsColumns"

export const ServiceBillingsManuallyContracts = () => {
  const { t } = useTranslation("service-billings")

  const {
    data: contracts,
    isFetching,
    isError,
    error,
  } = useQuery(["getManuallyBilledContracts"], () => getAllManuallyBilledContracts())

  const errorMsg = error as AxiosErrorDataType

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={isError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <DataGridListPage<ContractAdminResponse>
        showButton={false}
        title={t("")}
        rows={contracts ?? []}
        loading={isFetching}
        defaultPageSize={10}
        columns={ServiceBillingsManuallyContractsColumns()}
        data-testid="serviceBillingsManuallyContractsTable"
        initialState={{
          sorting: {
            sortModel: [{ field: "datePeriod", sort: "desc" }],
          },
        }}
        sx={{ ".MuiDataGrid-row": { cursor: "default" } }}
      />
    </>
  )
}
