import { IconButton } from "@mui/material"
import { GridActionsColDef } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import DateUtils from "../../../../../services/utils/DateUtils"
import { DefaultCell } from "../../../../../uikit/dataGridTable"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { OpenIconNew } from "../../../../../uikit/Shared.Icon"

export const ServiceBillingsColumns = () => {
  const { t } = useTranslation("service-billings")

  const serviceBillingsListColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 150,
      field: "billingDate",
      headerName: t("billingDate"),
      valueFormatter: (value) => (value ? DateUtils.getDeFormatDateWithMonthString(value) : "-"),
      renderCell: ({ formattedValue, ...rest }) => <DefaultCell {...rest} value={formattedValue} />,
    },
    {
      flex: 150,
      field: "billingNumber",
      headerName: t("invoiceNumber"),
      renderCell: DefaultCell,
    },
    {
      flex: 120,
      field: "activeState",
      headerName: t("shared:status.label"),
      valueGetter: (value: string) => t(`shared:status.${value}`),
      renderCell: ({ row }) => <StatusView statusType={row.activeState} />,
    },
    {
      flex: 150,
      field: "billingType",
      headerName: t("billingType"),
      valueGetter: (value: string) => t(`shared:billingType.${value}`),
      renderCell: ({ formattedValue, ...rest }) => <DefaultCell {...rest} value={formattedValue} />,
    },
    {
      flex: 80,
      field: "numberOfBillings",
      headerName: t("anzahl"),
      renderCell: DefaultCell,
    },
    {
      flex: 200,
      field: "contractName",
      headerName: "ZEV",
      renderCell: ({ value, ...rest }) => {
        return (
          <>
            <DefaultCell {...rest} value={value} />
            <IconButton data-testid="goToZevBtn">
              <OpenIconNew />
            </IconButton>
          </>
        )
      },
    },
  ]
  return serviceBillingsListColumns
}
