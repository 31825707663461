import { ContractAdminResponse } from "../../data/generated-sources/openapi"
import DateUtils from "./DateUtils"

export const getContractStartEndPeriod = ({
  startDate,
  endDate,
  formatType = "DD.MM.YYYY",
}: ContractAdminResponse & { formatType?: string }): string => {
  const formatedStartDate = DateUtils.getDeFormatDate(startDate, formatType)
  const formatedEndDate = endDate ? ` - ${DateUtils.getDeFormatDate(endDate, formatType)}` : ""
  return `${formatedStartDate}${formatedEndDate}`
}
