import { Grid, Paper } from "@mui/material"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { adminGetProductHistoryByContractId } from "../../../../domain/portal/admin/contracts/Contracts.Repository"
import { customerGetProductHistoryByContractId } from "../../../../domain/portal/manager/contracts/Contract.Repository"
import DateUtils from "../../../../services/utils/DateUtils"
import { getUniqueId } from "../../../../services/utils/SharedUtils"
import { DataGridTable, DefaultCell } from "../../../../uikit/dataGridTable"
import { FormSectionTitle } from "../../../../uikit/form/FormView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { isAdminPortal, useQueryDefaultOptions } from "../../../Shared.Utils"
import { ProductHistoryItemModel, ProductHistoryPropsModel } from "./ProductHistoryModel"

const ProductHistory = (props: ProductHistoryPropsModel) => {
  const { title, contractId } = props
  const { t } = useTranslation()
  const [productHistoryData, setProductHistoryData] = useState<ProductHistoryItemModel[]>([])

  const { data, isLoading, isRefetching, isSuccess, isError, remove, refetch } = useQuery(
    ["getProductHistory"],
    () =>
      isAdminPortal()
        ? adminGetProductHistoryByContractId(contractId)
        : customerGetProductHistoryByContractId(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    if (data?.data) {
      const formatData: ProductHistoryItemModel[] = data.data.productHistory.map((item) => {
        return {
          ...item,
          id: getUniqueId(),
        }
      })
      setProductHistoryData(formatData)
    }
  }, [data])

  useEffect(() => {
    contractId && refetch()
  }, [contractId, refetch])

  useEffect(() => () => remove(), [remove])

  const productHistoryColumns: Array<GridBaseColDef> = [
    {
      field: "contractName",
      headerName: t("products:field.label.products"),
      valueGetter: (_, row) => row.productName ?? "-",
      renderCell: DefaultCell,
      flex: 300,
    },
    {
      field: "contractStartDate",
      valueGetter: (_, row) => (row.contractStartDate ? DateUtils.getDeFormatWithDots(row.contractStartDate) : "-"),
      renderCell: DefaultCell,
      headerName: t("shared:form.startDate"),
      flex: 200,
    },
    {
      field: "contractEndDate",
      valueGetter: (_, row) => (row?.contractEndDate ? DateUtils.getDeFormatWithDots(row.contractEndDate) : "-"),
      renderCell: DefaultCell,
      headerName: t("shared:form.endDate"),
      flex: 200,
    },
  ]

  return (
    <Paper
      style={{
        boxShadow: isAdminPortal() ? "indigo" : "none",
      }}
    >
      <Grid container>
        {title ? (
          <Grid item md={12}>
            <FormSectionTitle label={title} />
          </Grid>
        ) : null}
        <Grid item md={12}>
          <ErrorAlert visible={isError && !isLoading} message={t("error-codes:OTHER")} />
          {isSuccess ? (
            <DataGridTable<ProductHistoryItemModel>
              loading={isLoading || isRefetching}
              rows={productHistoryData ?? []}
              columns={productHistoryColumns}
            />
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductHistory
