import { combineReducers } from "redux"
import { ProfileDetailReducer, ProfileDetailState } from "./profile/ProfileDetails.Reducer"
import billingsSlice, { BillingsState } from "./store/billingsSlice"
import contractSelectorSlice, { ContractSelectorState } from "./store/contractSelectorSlice"
import uiSlice, { UiState } from "./store/uiSlice"

export interface ManagerPortalState {
  profileDetails: ProfileDetailState
  ui: UiState
  billings: BillingsState
  contractSelector: ContractSelectorState
}

export const managerPortalReducer = combineReducers<ManagerPortalState>({
  profileDetails: ProfileDetailReducer,
  ui: uiSlice,
  billings: billingsSlice,
  contractSelector: contractSelectorSlice,
})
