import { Box } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { BoxTypeMap } from "@mui/system"

export const TinyPaddedBox = (props: DefaultComponentProps<BoxTypeMap>) => {
  const { children } = props
  return (
    <Box p={1} {...props}>
      {children}
    </Box>
  )
}

export const SmallPaddedBox = (props: DefaultComponentProps<BoxTypeMap>) => {
  const { children } = props
  return (
    <Box p={2} {...props}>
      {children}
    </Box>
  )
}

export const SmallPaddedHorizontalBox = (props: DefaultComponentProps<BoxTypeMap>) => {
  const { children } = props
  return (
    <Box pl={2} pr={2} {...props}>
      {children}
    </Box>
  )
}
