import { I18nNamespace } from "../../App.i18n"
import { SharedErrorI18n } from "../../Shared.Error.i18n"
import { SharedI18n } from "../../Shared.i18n"
import { UtilityUnitI18n } from "../admin/sharedComponentsConfig/utilityUnits/UtilityUnit.i18n"
import { DashboardI18n } from "./dashboard/Dashboard.i18n"
import { FaqI18n } from "./faq/Faq.i18n"
import { SettingsI18n } from "./managerSettings/Settings.i18n"
import { FeesI18n } from "./managerSettings/components/fees/Fees.i18n"
import { Onboarding18n } from "./onboarding/Onboarding.i18n"
import { ParticipantsI18n } from "./participants/Participants.i18n"
import { PricesI18n } from "./prices/Prices.i18n"
import { ProfileI18n } from "./profile/Profile.i18n"
import { SettlementsI18n } from "./settlements/Settlements.i18n"
import { ManagerUtilityUnitI18n } from "./utilityUnit/UtilityUnit.i18n"

export const managerPortalI18n: I18nNamespace[] = [
  DashboardI18n,
  ParticipantsI18n,
  SettlementsI18n,
  Onboarding18n,
  FaqI18n,
  PricesI18n,
  ProfileI18n,
  SharedI18n,
  SharedErrorI18n,
  UtilityUnitI18n,
  SettingsI18n,
  FeesI18n,
  ManagerUtilityUnitI18n,
]
