import { Grid } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { BoxTypeMap } from "@mui/system"

export const AlignEndBox = (props: Partial<DefaultComponentProps<BoxTypeMap>>) => {
  const { children, sx } = props
  return (
    <Grid container justifyContent="end" sx={sx}>
      {children}
    </Grid>
  )
}

export const AlignItemsCenterBox = (props: Partial<DefaultComponentProps<BoxTypeMap>>) => {
  const { children, sx } = props
  return (
    <Grid container alignItems="center" sx={sx}>
      {children}
    </Grid>
  )
}

export const AlignBottomBox = (props: Partial<DefaultComponentProps<BoxTypeMap>>) => {
  const { children } = props
  return (
    <Grid container justifyContent="start" alignItems="flex-end">
      {children}
    </Grid>
  )
}

export const SpaceBetweenBox = (props: Partial<DefaultComponentProps<BoxTypeMap>>) => {
  const { children } = props
  return (
    <Grid container justifyContent="space-between">
      {children}
    </Grid>
  )
}

export const SpaceBetweenAlignBottomBox = (props: Partial<DefaultComponentProps<BoxTypeMap>>) => {
  const { children } = props
  return (
    <Grid container justifyContent="space-between" alignItems="flex-end">
      {children}
    </Grid>
  )
}
