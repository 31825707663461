import { PlusIcon } from "../Shared.Icon"
import { FabButton } from "./FabButton"

interface PrimaryPlusButtonProps {
  onClick: () => void
  disabled?: boolean
  background?: string[]
  color?: string
  customSize?: number
}

export const PrimaryPlusButton = (props: PrimaryPlusButtonProps) => {
  const { onClick, disabled, color, background, customSize } = props
  return (
    <FabButton
      color={color}
      customSize={customSize}
      background={background}
      size="medium"
      icon={<PlusIcon fontSize="medium" />}
      onClick={onClick}
      disabled={disabled}
      data-testid="primaryPlusButton"
    />
  )
}

export const SmallPrimaryPlusButton = (props: PrimaryPlusButtonProps) => {
  const { onClick, disabled, color, background, customSize } = props
  return (
    <FabButton
      color={color}
      customSize={customSize}
      background={background}
      size="small"
      icon={<PlusIcon fontSize="small" />}
      onClick={onClick}
      disabled={disabled}
      style={{ opacity: disabled ? "0.4" : "1" }}
    />
  )
}
