import { I18nNamespace } from "../../../App.i18n"

export const SettingsI18n: I18nNamespace = {
  name: "settings",
  de: {
    "tab.billings": "Abrechnungseinstellungen",
    "tab.fees": "Gebühren",
    "tab.electricity": "Strompreise",
    "tab.costSharing": "Kostenanteile",
    "tab.productHistory": "Produktverlauf",
    "tab.zevApp": "Zev App",
    "billingPreferences.title":
      "Die folgenden Informationen werden den Teilnehmern auf der Abrechnung zur Verfügung gestellt:",
    "billingPreferences.preferences": "Abrechnungseinstellungen",
    "billingPreferences.nextBillingDate": "Nächstes Abrechnungsdatum",
    "billingPreferences.billingPeriod": "Abrechnungsperiode",
    "billingPreferences.mainContact": "Hauptansprechperson",
    "billingPreferences.paymentInfo": "Zahlungsinformationen",
    "billingPreferences.beneficiary": "Begünstigter",
    "billingPreferences.iban": "IBAN",
    "billingPreferences.period": "Zahlungsfrist in Tagen",
    "billingPreferences.vatTitle": "MwSt-ID",
    "billingPreferences.frequencyChangeDate": "Ihre Änderung wird am {{ date }} wirksam",
    "zevAppActivation.title": "ZEV Teilnehmerportal aktivieren",
    "zevAppActivation.description":
      "Mit dem ZEV Teilnehmerportal können die Nutzer ihren Strom-, Wasser- und Wärmeverbrauch einsehen.",
    "zevAppActivation.removeAccess.confirmTitle": "Zugang zur ZEV App für alle Teilnehmer entfernen?",
    "zevAppActivation.removeAccess.confirmMessage":
      "Durch das Deaktivieren dieser Option wird der Zugang zur ZEV App für alle Teilnehmer sofort entzogen. Möchten Sie wirklich fortfahren?",
    "zevAppActivation.giveAccess.confirmTitle": "Zugang zur ZEV App für alle Teilnehmer gewähren?",
    "zevAppActivation.giveAccess.confirmMessage":
      "Durch das Aktivieren dieser Option wird der Zugang zur ZEV App für alle Teilnehmer sofort gewährt. Möchten Sie wirklich fortfahren?",
    "zevAppActivation.removeAccess": "Zugang entfernen",
    "zevAppActivation.giveAccess": "Zugang gewähren",
    "zevAppActivation.cancel": "Abbrechen",
    "label.participant": "Teilnehmer",
  },
  en: {
    "tab.billings": "Billing Preferences",
    "tab.fees": "Fees",
    "tab.electricity": "Electricity prices",
    "tab.costSharing": "Cost sharing",
    "tab.productHistory": "Product history",
    "tab.zevApp": "Zev App",
    "billingPreferences.title": "The following information will be provided to participants on the statement:",
    "billingPreferences.preferences": "Billing preferences",
    "billingPreferences.nextBillingDate": "Next billing date",
    "billingPreferences.billingPeriod": "Billing Period",
    "billingPreferences.mainContact": "Main Contact",
    "billingPreferences.paymentInfo": "Payment information",
    "billingPreferences.beneficiary": "Beneficiary",
    "billingPreferences.iban": "IBAN",
    "billingPreferences.period": "Payment period in days",
    "billingPreferences.vatTitle": "MwSt-ID",
    "billingPreferences.frequencyChangeDate": "Your change will take effect on {{ date }}",
    "zevAppActivation.title": "Activate ZEV Participant Portal",
    "zevAppActivation.description":
      "With the ZEV Participant Portal, users can view their electricity, water and heat consumption.",
    "zevAppActivation.removeAccess.confirmTitle": "Remove access to the ZEV App for all participants?",
    "zevAppActivation.removeAccess.confirmMessage":
      "By disabling this option, access to the ZEV App will be removed immediately for all participants. Do you really want to proceed?",
    "zevAppActivation.giveAccess.confirmTitle": "Give access to the ZEV App for all participants?",
    "zevAppActivation.giveAccess.confirmMessage":
      "By enabling this option, access to the ZEV App will be granted immediately for all participants. Do you really want to proceed?",
    "zevAppActivation.removeAccess": "Remove access",
    "zevAppActivation.giveAccess": "Give access",
    "zevAppActivation.cancel": "Cancel",
  },
}
