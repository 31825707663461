import { CircularProgress } from "@mui/material"
import { ButtonTypeMap } from "@mui/material/Button"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { appThemeErrorColor, appThemeErrorDarkColor } from "../../app/Shared.Theme"
import { ButtonCtaIcon } from "../Shared.Icon"
import { GradientButton } from "./GradientButton"

interface ErrorButtonLoadingProps {
  id?: string
  isLoading: boolean
  label: string
}

export const ErrorButtonLoading = (props: ErrorButtonLoadingProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { isLoading } = props
  return (
    <GradientButton
      startColor={appThemeErrorColor}
      endColor={appThemeErrorDarkColor}
      endIcon={
        isLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : <ButtonCtaIcon fontSize="large" />
      }
      dataTestId="errorButtonLoading"
      {...props}
    />
  )
}
