import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
  ServiceBillingType,
  UnifiedServiceBillingAdminResponse,
} from "../../../../../data/generated-sources/openapi/api"
import { getAdminUnifiedServiceBillings } from "../../../../../domain/portal/admin/billings/Billings.Repository"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { DataGridListPage } from "../../dataGrid/DataGridListPage"
import { ServiceBillingsColumns } from "./ServiceBillingsColumns"

export const ServiceBillingsList = () => {
  const { t } = useTranslation("service-billings")
  const history = useHistory()

  const {
    data: billings,
    isFetching,
    isError,
    error,
  } = useQuery(["getServiceBillings"], () => getAdminUnifiedServiceBillings())

  const errorMsg = error as AxiosErrorDataType

  const handleCellClick = (field: string, { billingType, id, contractId }: UnifiedServiceBillingAdminResponse) => {
    if (field === "contractName") {
      return history.push(`/management-contracts/${contractId}`)
    }

    if (billingType === ServiceBillingType.INITIAL) {
      return history.push(`/billings/initial/details/${id}`)
    }

    if (billingType === ServiceBillingType.RECURRING) {
      return history.push(`/billings/recurring/details/${id}`)
    }
  }

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={isError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <DataGridListPage<UnifiedServiceBillingAdminResponse>
        loading={isFetching}
        rows={billings ?? []}
        showButton={false}
        title={t("")}
        data-testid="serviceBilingsListTable"
        columns={ServiceBillingsColumns()}
        onCellClick={({ field, row }) => handleCellClick(field, row)}
        pageSizeOptions={[5, 10, 25, 50, 100]}
      />
    </>
  )
}
