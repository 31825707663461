import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FabButton } from "../../../../../uikit/button"
import { PlusIcon } from "../../../../../uikit/Shared.Icon"

export const ServiceBillingsHeader = () => {
  const { t } = useTranslation("service-billings")
  const history = useHistory()

  return (
    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 4 }}>
      <Typography
        variant="h4"
        fontSize={24}
        fontWeight={300}
        textTransform={"uppercase"}
        data-testid="serviceBillingsHeaderTitle"
      >
        {t("serviceBilling.title")}
      </Typography>
      <FabButton
        size="medium"
        data-testid="serviceBillingsCreateButton"
        icon={<PlusIcon fontSize="medium" sx={{ color: "white" }} />}
        onClick={() => history.push("/billings/recurring/create")}
      />
    </Stack>
  )
}
