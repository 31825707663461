import { Box, IconButton, Paper } from "@mui/material"
import { GridColDef, GridRowHeightParams } from "@mui/x-data-grid"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Meter, ParticipantBillingsResponse } from "../../../../../data/generated-sources/openapi"
import { adminGetParticipantsBillings } from "../../../../../domain/portal/admin/billings/Billings.Repository"
import DateUtils from "../../../../../services/utils/DateUtils"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { OpenIconNew } from "../../../../../uikit/Shared.Icon"
import { appThemeSecondaryColor } from "../../../../Shared.Theme"
import { DataGridListPage } from "../../dataGrid/DataGridListPage"

const ParticipantBillingManagementPage = () => {
  const { t } = useTranslation("participantBillingManagement")
  const history = useHistory()
  const { data, isLoading } = useQuery(["participantBillingManagement"], () => adminGetParticipantsBillings())
  const tableData = data?.participantBillings ?? []

  const MeteringCodeItem = (meter: Meter) => {
    if (!meter || !meter?.meterId || !meter?.meteringCode) return null
    return (
      <p
        style={{
          maxHeight: "20px",
          color: appThemeSecondaryColor,
          cursor: "pointer",
          margin: "5px 0",
        }}
        onClick={() => goToMeterDetailPage(meter?.meterId)}
      >
        {meter?.meteringCode}
      </p>
    )
  }
  const goToContractDetailPage = (id: string) => {
    history.push(`/management-contracts/${id}`)
  }

  const goToMeterDetailPage = (id: string) => {
    history.push(`/meters/${id}`)
  }

  const columns: Array<GridColDef> = [
    {
      flex: 70,
      field: "billingStartDate",
      type: "date",
      headerName: t("participantBillingManagement:table.label.datePeriod"),
      valueGetter: (_, row) => new Date(row.billingStartDate),
      sortComparator: (v1, v2) => (v1?.billingStartDate > v2?.billingStartDate ? 1 : -1),
      renderCell: (params) => {
        const startDate = DateUtils.getDeFormatWithDots(params.row.billingStartDate)
        const endDate = DateUtils.getDeFormatWithDots(params.row.billingEndDate)
        return `${startDate} - ${endDate}`
      },
    },
    {
      flex: 200,
      field: "contractName",
      headerName: t("participantBillingManagement:table.label.contractName"),
      renderCell: (params) => {
        const contractName = params.row.contractName
        const missingMeters = params.row.missingDataMeters
        return (
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                maxHeight: "20px",
              }}
            >
              {contractName}
            </p>
            {missingMeters.length > 0 &&
              missingMeters.map((item: Meter) => (
                <div key={item.meterId}>
                  <MeteringCodeItem meterId={item.meterId} meteringCode={item.meteringCode} />
                </div>
              ))}
          </Box>
        )
      },
    },
    {
      flex: 50,
      field: "status",
      headerName: t("participantBillingManagement:table.label.status"),
      valueGetter: (value: string) => t(`shared:status.${value}`),
      renderCell: (params) => <StatusView statusType={params.row?.status} />,
    },
    {
      flex: 50,
      field: "type",
      headerName: t("participantBillingManagement:table.label.billingType"),
    },
    {
      flex: 20,
      field: "zev",
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerName: t("participantBillingManagement:table.label.zev"),
      renderCell: ({ row }) => (
        <IconButton data-testid="goToContractBtn" onClick={() => goToContractDetailPage(row.contractId)}>
          <OpenIconNew />
        </IconButton>
      ),
    },
  ]

  return (
    <Paper>
      <DataGridListPage<ParticipantBillingsResponse>
        showFilter={true}
        showButton={false}
        loading={isLoading}
        title={t("page.title")}
        data-testid="participantBillingManagementListPage"
        rows={tableData}
        columns={columns}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        getRowHeight={({ model }: GridRowHeightParams) => {
          const missingDataMeters = model.missingDataMeters
          if (missingDataMeters.length) {
            return 45 * (missingDataMeters.length + 1)
          }
          return 50
        }}
        sx={{
          "& .MuiDataGrid-cell": {
            lineHeight: "normal",
          },
        }}
      />
    </Paper>
  )
}

export default ParticipantBillingManagementPage
